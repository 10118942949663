/* App.scss */
@import "../node_modules/react-datepicker/dist/react-datepicker.css";

// New color palette
$primary-color: #3a5a78;
$secondary-color: #5f7a8b;
$background-color: #f4f7f9;
$text-color: #2c3e50;
$urgent-color: #b84c4c;
$important-color: #d2a54e;
$normal-color: #4c8db8;
$completed-color: #4a9764;

body {
  font-family: "Roboto", sans-serif;
  background-color: $background-color;
  margin: 0;
  padding: 0;
}

.app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: $primary-color;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 8px 8px 0 0;
  margin-bottom: 20px;

  h1 {
    margin: 0;
    font-size: 28px;
  }
}

main {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.task-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
  background-color: #f0f3f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .form-group {
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;

    label {
      font-size: 14px;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .task-input,
    .urgency-select,
    .due-date-input,
    .tags-input {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
      transition: border-color 0.3s, box-shadow 0.3s;

      &:focus {
        outline: none;
        border-color: $primary-color;
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
      }
    }

    .urgency-select {
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%232c3e50' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 10px center;
      padding-right: 30px;
    }
  }

  .add-button {
    flex: 1 1 100%;
    background-color: $primary-color;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transition: background-color 0.3s, transform 0.1s;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  .filter-select,
  .sort-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
}

.task-columns {
  display: flex;
  gap: 20px;
}

.column {
  flex-grow: 1;
  background-color: #f0f3f5;
  border-radius: 8px;
  padding: 15px;

  h2 {
    margin-top: 0;
    color: $text-color;
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.pending-column {
  flex-grow: 2;
}

.urgency-columns {
  display: flex;
  gap: 15px;
}

.urgency-column {
  flex-grow: 1;
  background-color: white;
  border-radius: 8px;
  padding: 10px;

  h3 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 10px;
  }

  &.urgent h3 {
    color: $urgent-color;
  }

  &.important h3 {
    color: $important-color;
  }

  &.normal h3 {
    color: $normal-color;
  }
}

.completed-column {
  background-color: #f0f5f2;

  h2 {
    color: $completed-color;
  }
}

.task {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding: 15px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

  .task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .task-title {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    .task-urgency {
      font-size: 12px;
      font-weight: 600;
      padding: 4px 8px;
      border-radius: 12px;
      text-transform: uppercase;

      &.urgent {
        background-color: $urgent-color;
        color: white;
      }

      &.important {
        background-color: $important-color;
        color: white;
      }

      &.normal {
        background-color: $normal-color;
        color: white;
      }

      &.completed {
        background-color: $secondary-color;
        color: white;
      }
    }
  }

  .task-body {
    margin-bottom: 10px;

    .task-dates {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #666;
      margin-bottom: 10px;

      span {
        margin-bottom: 5px;
      }

      input[type="date"] {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 4px;
        font-size: 14px;
      }
    }

    .task-tags {
      input {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;
      }
    }
  }

  .task-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }

  &.urgent {
    border-left: 4px solid $urgent-color;
  }

  &.important {
    border-left: 4px solid $important-color;
  }

  &.normal {
    border-left: 4px solid $normal-color;
  }

  &.completed {
    border-left: 4px solid $completed-color;
    background-color: #f0f5f2;
  }
}

// Responsive design
@media (max-width: 768px) {
  .task-form {
    flex-direction: column;
    .form-group {
      flex: 1 1 100%;
    }
  }

  .urgency-columns {
    flex-direction: column;
  }

  .task-columns {
    flex-direction: column;
  }
}

.edit-task-form {
  padding: 15px;
  background-color: #f0f3f5;
  border-radius: 8px;

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: 600;
    }

    input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      padding: 8px 12px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &[type="submit"] {
        background-color: $primary-color;
        color: white;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }

      &[type="button"] {
        background-color: #e0e0e0;
        color: $text-color;

        &:hover {
          background-color: #d0d0d0;
        }
      }
    }
  }
}

.date-picker-wrapper {
  width: 100%;
}

.date-picker-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background-color: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    outline: none;
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
  }
}

.react-datepicker {
  font-family: inherit;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: $primary-color;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 8px;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: $primary-color;
  color: #fff;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker__year-dropdown {
  width: 50%;
  left: 25%;
  border-radius: 8px;
}

.react-datepicker__year-option:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.react-datepicker__year-option:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.date-picker-input {
  padding: 10px;
  width: 92%;
}
